import {
  Alert,
  Button,
  CircularProgress,
  Snackbar,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import launcherIcon from "../img/launcherIcon.svg";
import launcherIconDark from "../img/launcherIcon_dark.svg";
import version from "../../package.json";

export const LandingPage = ({ onSubmit, onChange, state, handleClose }) => {
  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current.focus();
  }, []);
  if (state.isBusy) {
    return (
      <div className="loader">
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
        className="landingWrap "
      >
        <div className="sideStep rightBorder">
          <h1>Welcome</h1>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ligula
            lorem sed ut sed viverra lacus enim.
          </p> */}
          <div className="step">
            <span>1</span>Open BFIT Order App
          </div>
          <div className="step">
            <span>2</span>Add Screen / Open Settings Page
          </div>
          <div className="step">
            <span>3</span>Select your screen
          </div>
          <div className="step">
            <span>4</span>Enter your device code here
          </div>
        </div>
        <div className="sideStep">
          <div class="bfitBag">
            <img src={launcherIcon} className="light" />
            <img src={launcherIconDark} className="dark" />
          </div>
          <div className="bfittext">BFIT Orders</div>
          <label className="inputlabel">
            Enter 6-digit device code to connect display.{" "}
          </label>
          <input
            maxLength={6}
            className="textInput"
            placeholder="Device code"
            value={state.pin}
            ref={inputRef}
            onChange={(e) => onChange(e)}
          />
          <button
            className="btn"
            type="submit"
            disabled={state.pin === "" || state.isBusy}
          >
            Enter
          </button>
          <div className="version"> version {version.version}</div>
        </div>
      </form>
      <Snackbar
        open={state.open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {state.errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};
