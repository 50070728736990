import moment from "moment/moment";
import React, { useState, useEffect } from "react";

export const DateTime = () => {
  var [date, setDate] = useState(moment());

  useEffect(() => {
    var timer = setInterval(() => setDate(moment()), 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="footertext">{date.format("hh:mm a, MMMM D, YYYY")}</div>
  );
};

export default DateTime;
