import { dataRef, db } from "./lib/firebaseConfig";
import { Routes, Route, useNavigate } from "react-router-dom";
import { LandingPage } from "./views/LandingPage";
import { MainPage } from "./views/MainPage";
import { getDocs, query, where } from "firebase/firestore";
import { useImmer } from "use-immer";
import "./scss/main.scss";
import { useEffect } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { async } from "@firebase/util";

function App() {
  const navigate = useNavigate();
  const auth = getAuth();
  const [state, setState] = useImmer({
    pin: "",
    open: false,
    isBusy: false,
    errorMessage: "",
  });

  useEffect(() => {
    if (auth.currentUser) {
      return;
    } else {
      signInWithEmailAndPassword(
        auth,
        "eldho.johnson@codinghands.in",
        "Password#123"
      )
        .then((userCredential) => {
          const user = userCredential.user;
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          setState((draft) => {
            draft.open = true;
            draft.errorMessage = errorMessage;
          });
        });
    }
  }, []);

  const onChange = (e) => {
    setState((draft) => {
      draft.pin = e.target.value;
    });
  };

  const changeDisconnectStatus = async (id) => {
    const currentCollection = doc(db, "screens", id);
    // Set the "disconnect_device" field of the collection
    await updateDoc(currentCollection, {
      disconnect_device: false,
    });
    return true;
  };

  const onSubmit = () => {
    setState((draft) => {
      draft.isBusy = true;
    });
    const q = query(
      dataRef,
      where("pin", "==", state.pin),
      where("isActive", "==", true)
    );
    getDocs(q)
      .then((res) => {
        const data = res.docs.map((doc) => ({ id: doc.id }));
        if (data.length > 0) {
          changeDisconnectStatus(data[0].id).then((res) => {
            localStorage.setItem("screen-code", state.pin);
            setState((draft) => {
              draft.pin = "";
              draft.isBusy = false;
            });
            navigate("/view");
          });
        } else {
          setState((draft) => {
            draft.open = true;
            draft.errorMessage = "Invalid PIN";
            draft.isBusy = false;
          });
        }
      })
      .catch((err) => {
        setState((draft) => {
          draft.isBusy = false;
          draft.open = true;
          draft.errorMessage = err;
        });
      });
  };

  const handleClose = () => {
    setState((draft) => {
      draft.open = false;
    });
  };

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <LandingPage
              onSubmit={onSubmit}
              onChange={onChange}
              state={state}
              handleClose={handleClose}
            />
          }
        />
        <Route path="/view" element={<MainPage />} />
      </Routes>
    </>
  );
}

export default App;
