import { initializeApp } from "firebase/app";
import { getFirestore, collection } from "firebase/firestore";

const firebaseConfigLive = {
  apiKey: "AIzaSyAmA2bN3mjmoghEOvA7SgxgL3fRaxggRX8",
  authDomain: "ordering-96a6b.firebaseapp.com",
  databaseURL: "https://ordering-96a6b.firebaseio.com",
  projectId: "ordering-96a6b",
  storageBucket: "ordering-96a6b.appspot.com",
  messagingSenderId: "585914947132",
  appId: "1:585914947132:web:c7bb98261c089462045539",
  measurementId: "G-CT8GSZ21YM",
};

const firebaseConfig = {
  apiKey: "AIzaSyAYB51n6GMokrTy0IQDW5fJBpWisWmfoSY",
  authDomain: "bfit--order-ready.firebaseapp.com",
  projectId: "bfit--order-ready",
  storageBucket: "bfit--order-ready.appspot.com",
  messagingSenderId: "729554031999",
  appId: "1:729554031999:web:1e03bbb24ea2aaf2a4764a",
};
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const dataRef = collection(db, "screens");
